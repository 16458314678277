import { useEffect, useState } from "react";
import MDBox from "../../ui-kit/components/MDBox";
import { CardContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Feedback, FeedbackType } from "../../data";
import { AxiosResponse } from "axios";
import { axiosInstance } from "../../../axiosApi";
import Header2 from "../../components/Header2/Header2";
import PageWrapperLayout from "../../components/PageWrapperLayout/PageWrapperLayout";
import Footer from "../../components/Footer/Footer";
import MDTypography from "../../ui-kit/components/MDTypography";
import FeedbackSubmitCard from "../../components/FeedbackSubmitCard/FeedbackSubmitCard";

type FeedbackLineProps = {
    feedback: Feedback
}

const FeedbackLine = ({feedback}: FeedbackLineProps): JSX.Element => {
    return(
        <TableRow key={feedback.id}>
            <TableCell><MDTypography>{feedback.title}</MDTypography></TableCell>
            <TableCell><MDTypography>{feedback.category}</MDTypography></TableCell>
            <TableCell><MDTypography>{feedback.text}</MDTypography></TableCell>
            <TableCell><MDTypography>{feedback.status}</MDTypography></TableCell>
        </TableRow>
    )
}

type FeedbackTableCardProps = {
    feedbacks: Feedback[],
    setFeedbacks: React.Dispatch<React.SetStateAction<Feedback[]>>
}

const FeedbackTableCard =({feedbacks, setFeedbacks}: FeedbackTableCardProps): JSX.Element => {
    async function getCompanyUsers(){
        try{
          const {data, }: AxiosResponse<FeedbackType[]> = await axiosInstance.get(`/feedbacks/`);
          setFeedbacks(data.map((item) => new Feedback({
    
              id: item.id,
              title: item.title,
              text: item.text,
              status: item.status,
              category: item.category,
              
            })))
    
    
        }catch(error){
          console.log(error)
        }
      }
    
      useEffect(()=>{
        getCompanyUsers();
      }, [])
    return (
        <CardContent>
          <TableContainer component={Paper}>
            <Table >
              <TableHead sx={{ display: "table-header-group" }}>
                <TableRow>
                  <TableCell><MDTypography>Title</MDTypography></TableCell>
                  <TableCell><MDTypography>Category</MDTypography></TableCell>
                  <TableCell><MDTypography>Text</MDTypography></TableCell>
                  <TableCell><MDTypography>Status</MDTypography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {feedbacks.map((feedback) => <FeedbackLine key={feedback.id} feedback={feedback}/>)}

              </TableBody>
            </Table>
          </TableContainer>
          
        </CardContent>
    )
}

const FeedbackPageInner =({}): JSX.Element => {
    const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
    return(
        <>
            <FeedbackSubmitCard setFeedbacks={setFeedbacks} feedbacks={feedbacks}/>
            <FeedbackTableCard setFeedbacks={setFeedbacks} feedbacks={feedbacks}/>
        </>
    )
}

const FeedbackPage2 = ({}): JSX.Element => {
  return (
    <PageWrapperLayout>
      <Header2 />
      <MDBox sx={{mt:12}}>
        
      </MDBox>
      <MDBox sx={{ margin: '0 auto', width: '80%', height: '80%', minHeight: '80%', maxHeight: '80%', align:'center'}}>
        <FeedbackPageInner />
        <Footer />
      </MDBox>
      
    </PageWrapperLayout>
  )
}

export default FeedbackPage2