import axios from "axios";

//const baseURL = 'http://127.0.0.1:8001/api/';
const baseURL = 'https://chatbackend.aimloperations.com/api/';
//const baseURL = process.env.REACT_APP_BACKEND_REST_API_BASE_URL;

export const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 5000,
    headers: {
        "Authorization": 'JWT ' + localStorage.getItem('access_token'),
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

axiosInstance.interceptors.request.use(config => {
    config.timeout = 100000;
    return config;
})

axiosInstance.interceptors.response.use(
    response =>  response,
    error => {
        const originalRequest = error.config;

        // Prevent infinite loop
        if (error.response.status === 401 && originalRequest.url === baseURL+'/token/refresh/') {
            window.location.href = '/signin/';
            //console.log('remove the local storage here')
            return Promise.reject(error);
        }

        if(error.response.data.code === "token_not_valid" &&
           error.response.status == 401 && 
           error.response.statusText == 'Unauthorized') 
        {
            const refresh_token = localStorage.getItem('refresh_token');

            if (refresh_token){
                const tokenParts = JSON.parse(atob(refresh_token.split('.')[1]));

                const now = Math.ceil(Date.now() / 1000);
                //console.log(tokenParts.exp)

                if(tokenParts.exp > now){
                    return axiosInstance.post('/token/refresh/', {refresh: refresh_token}).then((response) => {
                        localStorage.setItem('access_token', response.data.access);
                        localStorage.setItem('refresh_token', response.data.refresh);
        
                        axiosInstance.defaults.headers['Authorization'] = 'JWT ' + response.data.access;
                        originalRequest.headers['Authorization'] = 'JWT ' + response.data.access;
        
                        return axiosInstance(originalRequest);
                    }).catch(err => {
                        console.log(err)
                    });

                }else{
                    console.log('Refresh token is expired');
                    window.location.href = '/signin/';

                }
            }else {
                console.log('Refresh token not available');
                window.location.href = '/signin/';
                
            }

            
            
        }
        return Promise.reject(error);
    }
);