import createCache, { EmotionCache } from "@emotion/cache";
import { setOpenConfigurator, useMaterialUIController } from "../../ui-kit/context";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import stylisRTLPlugin from "stylis-plugin-rtl";
import MDBox from "../../ui-kit/components/MDBox";
import { CacheProvider } from "@emotion/react";
import { CssBaseline, Icon, ThemeProvider } from "@mui/material";
import themeDark from "../../ui-kit/assets/theme-dark";
import theme from "../../ui-kit/assets/theme";
import themeRtl from "../../ui-kit/assets/theme/theme-rtl";
import themeDarkRTL from "../../ui-kit/assets/theme-dark/theme-rtl";

type PageWrapperLayoutProps = {
    children: React.ReactNode;
}

const PageWrapperLayout = ({children}: PageWrapperLayoutProps): JSX.Element => {
    
    const [controller, dispatch] = useMaterialUIController();
    const {
        direction,
        openConfigurator,
        darkMode,
    } = controller;
    const [rtlCache, setRtlCache] = useState<EmotionCache | null>(null);
    const { pathname } = useLocation();

    // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [stylisRTLPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if(document.scrollingElement){
        document.scrollingElement.scrollTop = 0;
    }
  }, [pathname]);

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRtl}>
        <CssBaseline />
        {children}
        
        
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
       {children}
      
    </ThemeProvider>
  );

    
}

export default PageWrapperLayout;