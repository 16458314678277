import { AccountContext } from "./AccountContext"
import { AuthContext } from "./AuthContext";

const { useEffect, createContext, useRef, useState, useContext } = require("react")


const WebSocketContext = createContext()

function WebSocketProvider({ children }) {
    const { authenticated, loading } = useContext(AuthContext);
    const ws = useRef(null)
    const [socket, setSocket] = useState(null)
    const channels = useRef({}) // maps each channel to the callback
    const { account, setAccount } = useContext(AccountContext)
    const [currentChannel, setCurrentChannel] = useState('')
    /* called from a component that registers a callback for a channel */
    const subscribe = (channel, callback) => {
        //console.log(`Subbing to ${channel}`)
        setCurrentChannel(channel)
        channels.current[channel] = callback
    }
    /* remove callback  */
    const unsubscribe = (channel) => {
        delete channels.current[channel]
    }

    const sendMessage = (message, conversation_id, file, fileType) => {
        console.log('Sending a message')
        if (socket && socket.readyState === WebSocket.OPEN){
            console.log('Socket is open')
            
            if (file){

            
                const reader = new FileReader();
                reader.onload = () => {
                    const base64File = reader.result?.toString().split(',')[1];
                    if (base64File){
                        const data = {
                            message: message,
                            conversation_id: conversation_id,
                            email: account?.email,
                            file: base64File,
                            fileType: fileType,
                        }
                        console.log('Socket send with file')
                        socket.send(JSON.stringify(data))

                    }
                }
                reader.readAsDataURL(file)
            }else{
                const data = {
                    message: message,
                    conversation_id: conversation_id,
                    email: account?.email,
                    file: null,
                    fileType: null
                }
                console.log('Socket send without file')
                socket.send(JSON.stringify(data))
            }
            //socket.send(`${conversation_id} | ${message}`)
            
        }else{
            console.log('Error sending message. WebSocket is not open')
        }

    }
    useEffect(() => {
        /* WS initialization and cleanup */
        if (account){
            console.log(`ws account: ${account?.email}`)
            
            //ws.current = new WebSocket(`ws://127.0.0.1:8001/ws/chat_again/`);
            ws.current = new WebSocket('wss://chatbackend.aimloperations.com/ws/chat_again/')
            //ws.current = process.env.REACT_APP_BACKEND_WS_API_BASE_URL;

            ws.current.onopen = () => { setSocket(ws.current); console.log('WS open') }
            ws.current.onclose = () => { console.log('WS close') }
            ws.current.onmessage = (message) => {
                const data = message.data
                // lookup for an existing chat in which this message belongs
                // if no chat is subscribed send message to generic channel
                const chatChannel = Object.entries(channels.current)[0][0]

                if (channels.current[chatChannel]) {
                    /* in chat component the subscribed channel is `MESSAGE_CREATE_${id}` */
                    channels.current[chatChannel](data)
                } else {
                    /* in notifications wrapper the subscribed channel is `MESSAGE_CREATE` */
                    console.log('Error')
                    // channels.current[type]?.(data)
                }
            }
            return () => { ws.current.close() }

        }
        
        
    }, [account])

    

    /* WS provider dom */
    /* subscribe and unsubscribe are the only required prop for the context */
    return (
        <WebSocketContext.Provider value={[subscribe, unsubscribe, socket, sendMessage]}>
            {children}
        </WebSocketContext.Provider>
    )
}

export { WebSocketContext, WebSocketProvider }