import React, { ReactNode } from "react";
import { Field, ErrorMessage, FieldInputProps } from "formik";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";

export interface CustomSelectItem {
  label: string;
  value: string;
}

interface FormikSelectProps {
  name: string;
  items: CustomSelectItem[];
  label: string;
  required?: boolean;
}

interface MaterialUISelectFieldProps extends FieldInputProps<string> {
  errorString?: string;
  children: ReactNode;
  label: string;
  required: boolean;
}

const MaterialUISelectField = ({
  errorString,
  label,
  children,
  value,
  name,
  onChange,
  onBlur,
  required
}: MaterialUISelectFieldProps): JSX.Element => {
  return (
    <FormControl fullWidth
    
    
        variant={"outlined"}>
      <InputLabel required={required}
    
      
      variant={"outlined"}>{label}</InputLabel>
      <Select name={name} onChange={onChange} onBlur={onBlur} value={value} size={"small"}
    
        variant={"outlined"}>
        {children}
      </Select>
      <FormHelperText>{errorString}</FormHelperText>
    </FormControl>
  );
};

const CustomSelect = ({ name, items, label, required = false }: FormikSelectProps):JSX.Element => {
  return (
    <div className="FormikSelect">
      <Field
        name={name}
        as={MaterialUISelectField}
        label={label}
        errorString={<ErrorMessage name={name} />}
        required={required}
    
        variant={"outlined"}
      >
        {items.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Field>
    </div>
  );
};

export default CustomSelect;