import { Card, CardContent, Divider, Switch } from "@mui/material";
import MDTypography from "../../ui-kit/components/MDTypography";
import MDBox from "../../ui-kit/components/MDBox";
import {
  useMaterialUIController,
  setDarkMode,
} from "../../ui-kit/context";

const ThemeCard = ({}): JSX.Element => {
    const [controller, dispatch] = useMaterialUIController();
    const {
      darkMode,
    } = controller;
    
    const handleDarkMode = () => setDarkMode(dispatch, !darkMode);
    return(
        <Card sx={{ mb: 1}}>
            <CardContent>
              <MDTypography variant="h3">
                Themes

              </MDTypography>
            </CardContent>
            <Divider />
            <CardContent>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <MDTypography variant="h6">Light / Dark</MDTypography>

          <Switch checked={darkMode} onChange={handleDarkMode} disabled={true} />
        </MDBox>
            </CardContent>
        </Card>
    )
}

export default ThemeCard;
