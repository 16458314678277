import React, { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import PageWrapperLayout from '../../components/PageWrapperLayout/PageWrapperLayout';
import MDBox from '../../ui-kit/components/MDBox';
import { Card, CardContent, Divider } from '@mui/material';
import MDTypography from '../../ui-kit/components/MDTypography';
import Footer from '../../components/Footer/Footer';
import Header2 from '../../components/Header2/Header2';

const NotFound = ({}): JSX.Element => {
    
  return (
    <PageWrapperLayout>
      <MDBox sx={{ margin: '0 auto', width: '80%', height: '80%', minHeight: '80%', maxHeight: '80%', align:'center'}}>
        <Header2 />
        <MDBox sx={{mt:12}}>
        
      </MDBox>

        <Card>
          <CardContent>
            <MDTypography variant="h3">
              Not Found
            </MDTypography>
          </CardContent>
          <Divider />
          <CardContent>
          <MDTypography >
            The page is not found

          </MDTypography>
          </CardContent>
        </Card>
        <Footer/>

      </MDBox>
    </PageWrapperLayout>
  );
};

export default NotFound;