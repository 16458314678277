import { ReactNode, useState, createContext, useEffect, useContext } from "react"
import { Account, AccountType } from "../data";
import { AuthContext } from "./AuthContext";
import { AxiosResponse } from "axios";
import { axiosInstance } from "../../axiosApi";

type AccountProviderProps ={
    children? : ReactNode;
}

type IAccountContext = {
    account: Account | undefined;
    setAccount: (account: Account | undefined) => void;
}

const initialValues = {
    account: undefined,
    setAccount: () => {}
}

const AccountContext = createContext<IAccountContext>(initialValues);

const AccountProvider = ({children}: AccountProviderProps) => {
    const [account, setAccount] = useState<Account | undefined>(initialValues.account);
    const { authenticated, loading } = useContext(AuthContext);

    async function getAccount (){
        const get_user_response: AxiosResponse<AccountType> = await axiosInstance.get('/user/get/')          

            const account: Account = new Account({
                email: get_user_response.data.email,
                first_name: get_user_response.data.first_name,
                last_name: get_user_response.data.last_name,
                is_company_manager: get_user_response.data.is_company_manager,
                has_signed_tos: get_user_response.data.has_signed_tos,
                company: {
                    id: get_user_response.data.company?.id,
                    name: get_user_response.data.company?.name,
                    state: get_user_response.data.company?.state,
                    zipcode: get_user_response.data.company?.zipcode,
                    address: get_user_response.data.company?.address,
                }
                
            });
            setAccount(account);

    }


    useEffect(() => {
        if(!loading && authenticated){
            getAccount();
            
        }

    }, [authenticated])
    return (
        <AccountContext.Provider value={{account, setAccount}}>
            {children}
        </AccountContext.Provider>
    )
}

export { AccountContext, AccountProvider }