import React, { Component, useContext } from 'react';
import './App.css';
import Dashboard from './llm-fe/pages/Dashboard/Dashboard';
import { Routes, Route, createRoutesFromElements, Outlet, Navigate, useLocation } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import SignIn from './llm-fe/pages/SignIn/SignIn';
import PasswordReset from './llm-fe/pages/PasswordReset/PasswordReset';
import NotFound from './llm-fe/pages/NotFound/NotFound';
import { AuthContext, AuthProvider } from './llm-fe/contexts/AuthContext';
import AccountPage from './llm-fe/pages/Account/Account';
import AsyncDashboard from './llm-fe/pages/AsyncDashboard/AsyncDashboard';
import TermsOfService from './llm-fe/pages/TermsOfService/TermsOfService';
import SetPassword from './llm-fe/components/SetPassword/SetPassword';
import FeedbackPage from './llm-fe/pages/FeedbackPage/FeedbackPage';
import { Typography } from '@mui/material';
import AsyncDashboard2 from './llm-fe/pages/AsyncDashboard2/AsyncDashboard2';
import FeedbackPage2 from './llm-fe/pages/FeedbackPage2/FeedbackPage2';
import Account2 from './llm-fe/pages/Account2/Account2';

const ProtectedRoutes = () => {
  const { authenticated, needsNewPassword, loading } = useContext(AuthContext);
  console.log(authenticated, needsNewPassword, loading)
  if(loading){
    console.log('return loading')
    return(
      <div>Loading</div>
    )
  }
  console.log('returning something else')
  if(!authenticated) return <Navigate to='/signin/' replace />
  //if(!needsNewPassword) return <Navigate to='/password_reset/' replace/>

  return <Outlet key={Date.now()}/>
}

class App extends Component {
  
  render() {
    return (
      <div className='site'>
        <main>
          <div className="main-container">
            
          
            <Routes>
              <Route path='*' element={<NotFound />} />
              
              <Route path={"/signin/"} Component={SignIn}/>
              <Route path={"/password_reset/"} Component={PasswordReset}/>
              <Route path={'/set_password/'} Component={SetPassword}/>
              

              
              <Route element={<ProtectedRoutes />}>
                <Route path={"/"} index={true} Component={AsyncDashboard2}/>
                <Route path={"/account/"} Component={Account2}/>
                <Route path={"/terms_of_service/"} Component={TermsOfService}/>
                <Route path={"/feedback/"} Component={FeedbackPage2}/>

              </Route>

            </Routes>
          
          </div>
          
        </main>
      </div>
      
      
    );

  }
  
}

export default App;

