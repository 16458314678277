import { AppBar, Button, Toolbar } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useMaterialUIController } from '../../ui-kit/context';
import { boolean } from 'yup';
import MDBox from '../../ui-kit/components/MDBox';
import {
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
    navbarMobileMenu,
  } from "../../ui-kit/examples/Navbars/DashboardNavbar/styles";
import Breadcrumbs from '../../ui-kit/examples/Breadcrumbs';
import { AccountContext } from '../../contexts/AccountContext';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../../axiosApi';



type Header2Props = {
    absolute?: Boolean;
    light?: Boolean;
    isMini?: Boolean;
}

const Header2 = ({ absolute=false, light=false, isMini=false }: Header2Props): JSX.Element => {
    const {authenticated, setAuthentication} = useContext(AuthContext);
    const { account, setAccount } = useContext(AccountContext);
    const navigate = useNavigate();
    const handleSignOut = async () => {
        try{
          const response = await axiosInstance.post('blacklist/',{
            'refresh_token': localStorage.getItem("refresh_token")
          })
          localStorage.removeItem('access_token')
          localStorage.removeItem('refresh_token')
          axiosInstance.defaults.headers['Authorization'] = null;
          setAuthentication(false)
          setAccount(undefined);
          navigate('/signin/')
        }finally{
          
        }
      }
    
    const handleDashboardClick = async () => {
        navigate('/')
      }
    
      const handleAccountClick = async () => {
        navigate('/account/')
      }
    
      const handleFeedbackClick = async () => {
        navigate('/feedback/')
      }
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
    return (
        <AppBar
            position={absolute ? 'absolute' : navbarType}
            color='info'
            sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
            >
                {/* <Toolbar> sx={(theme) => navbarContainer(theme)}> */}
                <Toolbar sx={{justifyContent: 'start'}}>
                    <MDBox>
                        <Button color="inherit" onClick={handleDashboardClick}>Dashboard</Button>

                    </MDBox>
                    <MDBox sx={{marginLeft: "auto"}}>
                    <Button color="inherit" onClick={handleAccountClick}>Account</Button>
        
                    <Button color="inherit" onClick={handleFeedbackClick} >Feedback</Button>
                    
                    <Button color="inherit" onClick={handleSignOut} >Sign Out</Button>

                    </MDBox>
                    
                    

                </Toolbar>
            </AppBar>

    
    
    )
}

export default Header2;