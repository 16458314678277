import createCache, { EmotionCache } from "@emotion/cache";
import { setMiniSidenav, setOpenConfigurator, useMaterialUIController } from "../../ui-kit/context";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import stylisRTLPlugin from "stylis-plugin-rtl";
import MDBox from "../../ui-kit/components/MDBox";
import { CacheProvider } from "@emotion/react";
import { CssBaseline, Icon, ThemeProvider } from "@mui/material";
import brandWhite from "../../ui-kit/assets/images/logo-ct.png";
import brandDark from "../../ui-kit/assets/images/logo-ct-dark.png";
import themeDark from "../../ui-kit/assets/theme-dark";
import themeDarkRTL from "../../ui-kit/assets/theme-dark/theme-rtl";
import theme from "../../ui-kit/assets/theme";
import themeRTL from "../../ui-kit/assets/theme/theme-rtl";
import Sidenav from "../../ui-kit/examples/Sidenav";
import Configurator from "../../ui-kit/examples/Configurator";


type DashboardWrapperLayoutProps = {
    children: React.ReactNode;
}

const DashboardWrapperLayout = ({children}: DashboardWrapperLayoutProps): JSX.Element => {
    
        const [controller, dispatch] = useMaterialUIController();
    const {
        miniSidenav,
        direction,
        layout,
        openConfigurator,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const [rtlCache, setRtlCache] = useState<EmotionCache | null>(null);
    const { pathname } = useLocation();

    // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [stylisRTLPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if(document.scrollingElement){
        document.scrollingElement.scrollTop = 0;
    }
  }, [pathname]);

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Chat by AI ML, Operations, LLC"
              routes={[]} // {routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            {/* <Configurator />
            {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        {children}
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="AI ML, Operations, LLC"
            routes={[]} // {routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {/* <Configurator />
          {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      {children}
    </ThemeProvider>
  );
    
}

export default DashboardWrapperLayout;