import { useContext, useEffect, useRef, useState } from "react"
import { WebSocketContext } from "../../contexts/WebSocketContext"
import { AccountContext } from "../../contexts/AccountContext"
import Markdown from "markdown-to-jsx"
import { Card, CardContent, CircularProgress } from "@mui/material"
import MDTypography from "../../ui-kit/components/MDTypography"
// import { CodeBlock } from "react-code-blocks"
// import CustomCodeBlock from "../CustomPreBlock/CustomPreBlock"
// import CustomPreBlock from "../CustomPreBlock/CustomPreBlock"

type ConversationDetailCardProps = {
    message: string
    user_created: boolean

}

type ConversationWebSocketCardProps = {
    user_created: boolean
}

export const ConversationWebSocketCard = ({user_created}: ConversationWebSocketCardProps): JSX.Element => {
    const type = user_created ? 'info' : 'warning'
    const text_align = user_created ? 'right' : 'left'
    
    const [subscribe, unsubscribe] = useContext(WebSocketContext)
    const { account, setAccount } = useContext(AccountContext)
    const messageRef = useRef('')
    const [stateMessage, setStateMessage] = useState<string>('')
    
    
    useEffect(() => {
        /* register a consistent channel name for identifing this chat messages */
        const channelName = `ACCOUNT_ID_${account?.email}`

        /* subscribe to channel and register callback */        
        subscribe(channelName, (message: string) => {
            /* when a message is received just add it to the UI */
            messageRef.current += message
            
            setStateMessage(stateMessage.concat(message))
            
        })

        return () => {
            /* unsubscribe from channel during cleanup */
            unsubscribe(channelName)
        }
    }, [account, subscribe, unsubscribe])
    
    //console.log(stateMessage)
    
    return (
        <Card sx={{margin: '0.25rem'}}>
            <CardContent style={{textAlign: `${text_align}`}}>
            <Markdown 
                    className='display-linebreak' 
                    style={{whiteSpace: "pre-line"}}
                    options={{
                        overrides: {
                            span: {
                                component: MDTypography,
                            }
                        }
                    }}
                >{stateMessage}</Markdown>
            </CardContent>
        </Card>
    )
}

const ConversationDetailCard = ({message, user_created}: ConversationDetailCardProps): JSX.Element => {
    const type = user_created ? 'info' : 'dark'
    if(user_created){

    }
    const text_align = user_created ? 'right' : 'left'
    if (message.length === 0){
        return(
            <Card sx={{margin: '0.25rem'}}>
                <CardContent className='card-body-small text-dark ' style={{textAlign: `right`, marginRight:  '1rem', marginLeft:  '1rem', marginTop: '1rem', marginBottom: '1rem'}}>
                    <CircularProgress color="inherit"/>
                </CardContent>
            </Card>
        )
    }else{
        const newMessage: string = message.replace("```", "\n```\n");        
    
        return (

        
        <Card sx={{margin: '0.25rem'}}>
            <CardContent sx={{textAlign: `${text_align}`, marginRight:  '1rem', marginLeft:  '1rem', marginTop: '1rem', marginBottom: '1rem'}}>
                <Markdown 
                    className='display-linebreak' 
                    style={{whiteSpace: "pre-line"}}
                    options={{
                        overrides: {
                            span: {
                                component: MDTypography,
                            }
                        }
                    }}
                >{newMessage}</Markdown>
            </CardContent>
        </Card>
        )

    }
    
}

export default ConversationDetailCard;