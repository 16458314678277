import { useContext, useEffect, useState } from "react";
import PageWrapperLayout from "../../components/PageWrapperLayout/PageWrapperLayout";
import { AccountContext } from "../../contexts/AccountContext";
import MDTypography from "../../ui-kit/components/MDTypography";
import { Account, AccountType } from "../../data";
import * as Yup from 'yup';
import { Card, CardContent, Divider, IconButton, InputAdornment, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { DeleteForever, Send } from "@mui/icons-material";
import { axiosInstance } from "../../../axiosApi";
import { AxiosResponse } from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDButton from "../../ui-kit/components/MDButton";
import MDBox from "../../ui-kit/components/MDBox";
import Header2 from "../../components/Header2/Header2";
import Footer from "../../components/Footer/Footer";
import ThemeCard from "../../components/ThemeCard/ThemeCard";

type AccountUpdateValues = {
    email: string,
}

type AccountInformationProps = {
    account: Account | undefined
}

type InviteValues = {
    email: string
}
const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("This is requried")
    }
)

type AddUserCardProps = {
    getCompanyUsers: ()=> void;
}

type CompanyAccountLineProps = {
    user: Account
    handleUserUpdate: (email: string, field: string, value: string) => void
}

const CompanyAccountLine = ({user, handleUserUpdate}: CompanyAccountLineProps): JSX.Element => {
    const {account} = useContext(AccountContext);
  
    return(
      <TableRow key={user.email}>
        <TableCell><MDTypography> {user.email}</MDTypography></TableCell>
        <TableCell><MDTypography> {user.first_name}</MDTypography></TableCell>
        <TableCell><MDTypography>{user.last_name}</MDTypography></TableCell>
        <TableCell >
          <Switch checked={user.is_company_manager} onChange={(event) => handleUserUpdate(user.email, 'company_manager', event.target.value)} disabled={account?.email === user.email} />
        </TableCell>
        <TableCell >
          <Switch checked={user.is_active} onChange={(event) => handleUserUpdate(user.email, 'is_active', event.target.value)} />
        </TableCell>
        <TableCell >
          <Switch checked={user.has_password && user.has_signed_tos} onChange={(event) => handleUserUpdate(user.email, 'has_password', event.target.value)} disabled={!user.has_password} />
        </TableCell>
        <TableCell>
          <IconButton>
            <DeleteForever color="warning" onClick={() => handleUserUpdate(user.email, 'delete', '')} />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }

  const AddUserCard = ({getCompanyUsers}: AddUserCardProps): JSX.Element => {
    const initialValues = {'email': '',}
    const handleInvite = async ({email}: InviteValues, {resetForm}: any): Promise<void> => {
      try{
        await axiosInstance.post('/user/invite/', {
          'email': email
        })
        getCompanyUsers()
        
      } catch{
        // put a message here
      }
      resetForm();
      
    }
    return(
      <Card sx={{mt:1}}>
        <CardContent>
              <MDTypography variant="h3">
                Invite A User

              </MDTypography>
            </CardContent>
            <Divider />
      
        <CardContent>
          <Formik
          initialValues={initialValues}
          onSubmit={handleInvite}
          validateOnMount
          validationSchema={validationSchema}>
            {(formik) => 
              <Form>
                <div className="row">
                  <div className='col-12'>
                    <Field
                      name={"email"}
                      fullWidth
                      as={TextField}
                      label={"Email"}
                      errorstring={<ErrorMessage name={"prompt"}/>}
                      size={'small'}
                      role={undefined}
                      tabIndex={-1}
                      margin={"dense"}
                      variant={"outlined"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <MDButton
                              type={'submit'}
                              startIcon={<Send/>}
                              disabled={!formik.isValid || formik.isSubmitting}>
                              <></>
  
                            </MDButton>
                          </InputAdornment>
                        )
                      }}
                    >
  
                    </Field>
                  </div>
                </div>
                
              </Form>
            }
          </Formik>
          
          
        </CardContent>
      </Card>
    )
  }
  
  const CompanyManagerCard = ({}): JSX.Element => {
    const [users, setUsers] = useState<Account[]>([]);
    const {account}= useContext(AccountContext);
    const [showModal, setShowModal] = useState<boolean>(false);
  
    const handleUserUpdate = async(email: string, field: string, value: string): Promise<void> => {
      //console.log(email, field, value)
      if(field === 'delete'){
      await axiosInstance.delete(`/company_users`, {   
        data: {'email':email}
          
        })
      }else {
        await axiosInstance.post(`/company_users`, {
          'email':email,
          'field': field,
          'value': value
        });
      }
  
      
  
      // get all of th edata again
      try{
        const {data, }: AxiosResponse<AccountType[]> = await axiosInstance.get(`/company_users`);
        setUsers(data.map((item) => new Account({
  
            email: item.email,
            first_name: item.first_name,
            last_name: item.last_name,
            is_company_manager: item.is_company_manager,
            has_password: item.has_usable_password,
            is_active: item.is_active,
            company: undefined
          })))
  
  
      }catch(error){
        console.log(error)
      }
  
    }
  
    async function getCompanyUsers(){
      try{
        const {data, }: AxiosResponse<AccountType[]> = await axiosInstance.get(`/company_users`);
        console.log(data)
        setUsers(data.map((item) => new Account({
  
            email: item.email,
            first_name: item.first_name,
            last_name: item.last_name,
            is_company_manager: item.is_company_manager,
            has_password: item.has_usable_password,
            is_active: item.is_active,
            has_signed_tos: item.has_signed_tos,
            company: undefined
          })))
  
  
      }catch(error){
        console.log(error)
      }
    }
  
    useEffect(()=>{
      getCompanyUsers();
    }, [])
    console.log(users)
  
    return(
      <>
        <Card>
          <CardContent>
              <MDTypography variant="h3">
                Company Accounts

              </MDTypography>
            </CardContent>
            <Divider />
          
          <CardContent>
            <TableContainer component={Paper}>
              <Table >
                <TableHead sx={{ display: "table-header-group" }}>
                  <TableRow>
                    <TableCell><MDTypography>Email</MDTypography></TableCell>
                    <TableCell><MDTypography>First Name</MDTypography></TableCell>
                    <TableCell><MDTypography>Last Name</MDTypography></TableCell>
                    <TableCell><MDTypography>Is Manager</MDTypography></TableCell>
                    <TableCell><MDTypography>Is Active</MDTypography></TableCell>
                    <TableCell><MDTypography>Has Password</MDTypography></TableCell>
                    <TableCell><MDTypography>Delete</MDTypography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {users.map((user) => <CompanyAccountLine user={user} handleUserUpdate={handleUserUpdate} key={user.email}/>)}
  
                </TableBody>
              </Table>
            </TableContainer>
            
          </CardContent>
        </Card>
        <AddUserCard getCompanyUsers={getCompanyUsers} />
      </>
      
    )
  }

const AccountPage =({}): JSX.Element => {
    const { account } = useContext(AccountContext)
    if(account?.is_company_manager){

        return(
          <>
            <ThemeCard />
            <CompanyManagerCard /> 
          </>
        
      )

    }else{
        return(
          <>
            <ThemeCard />
            <MDTypography>Account and prompt information will be available soon</MDTypography>
          </>
        )
    }
    
}

const Account2 = ({}): JSX.Element => {
  return (
    <PageWrapperLayout>
      <Header2 />
      <MDBox sx={{mt:12}}>
                
      </MDBox>
      <MDBox sx={{ margin: '0 auto', width: '80%', height: '80%', minHeight: '80%', maxHeight: '80%', align:'center'}}>
        <AccountPage />
        <Footer />
      </MDBox>
      
      
    </PageWrapperLayout>
  )
}

export default Account2