import { Card, CardContent, Divider, InputAdornment, } from "@mui/material"

import DashboardLayout from "../../ui-kit/examples/LayoutContainers/DashboardLayout"

import MDBox from "../../ui-kit/components/MDBox"
import { useMaterialUIController } from "../../ui-kit/context"
import { useContext, useEffect, useRef, useState } from "react"

// Images
import MDTypography from "../../ui-kit/components/MDTypography"
import { CardFooter } from "react-bootstrap"
import MDInput from "../../ui-kit/components/MDInput"
import { ErrorMessage, Field, Form, Formik } from "formik"
import MDButton from "../../ui-kit/components/MDButton"
import { AttachFile, Send } from "@mui/icons-material"
import Header2 from "../../components/Header2/Header2"
import DashboardWrapperLayout from "../../components/DashboardWrapperLayout/DashboardWrapperLayout"
import * as Yup from 'yup';
import { Announcement, AnnouncementType, Conversation, ConversationPrompt, ConversationPromptType, ConversationType } from "../../data"
import { axiosInstance } from "../../../axiosApi"
import { AxiosResponse } from "axios"
import { ConversationContext } from "../../contexts/ConversationContext"
import Markdown from "markdown-to-jsx"
import ConversationDetailCard from "../../components/ConversationDetailCard/ConversationDetailCard"
import { WebSocketContext } from "../../contexts/WebSocketContext"
import { AccountContext } from "../../contexts/AccountContext"
import { styled } from '@mui/material/styles';
import Footer from "../../components/Footer/Footer"

type RenderMessageProps= {
  response: string
  index: number
};

type AsyncChatProps = {
  selectedConversation: number | undefined
  conversationTitle: string
  conversations: Conversation[]
  setConversations: React.Dispatch<React.SetStateAction<Conversation[]>>
  setSelectedConversation: React.Dispatch<React.SetStateAction<number | undefined>>
  drawerWidth:  number;
}

const validationSchema = Yup.object().shape({
  prompt: Yup.string().min(1, "Need to have at least one character").required("This is requried")
}
)

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

type PromptValues = {
  prompt: string;
  file: Blob | null;
  fileType: string | null;

};

const AsyncDashboardInner =({}): JSX.Element => {
  const [controller, dispatch] = useMaterialUIController();
    const {
        darkMode,
    } = controller;
    const buttonColor = darkMode? 'dark' : 'light';
    const [announcements, setAnnouncement] = useState<Announcement[]>([]);
    const [subscribe, unsubscribe, socket, sendMessage] = useContext(WebSocketContext)
    const { account } = useContext(AccountContext)
    
    const [reconnectAttempts, setReconnectAttempts] = useState<number>(0);
    const maxReconnectAttempts = 5;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const {conversations, selectedConversation, setSelectedConversation} = useContext(ConversationContext);
    const [conversationDetails, setConversationDetails] = useState<ConversationPrompt[]>([])
    const selectedConversationRef = useRef<undefined | number>(undefined)
    const [stateMessage, setStateMessage] = useState<string>('')

    const messageResponse = useRef('');
    const messageRef = useRef('')
    const messageResponsePart = useRef(0);
    const conversationRef = useRef(conversationDetails)


    async function GetConversationDetails(){
      if(selectedConversation){
          
          try{
              //console.log('GetConversationDetails')
              //setPromptProcessing(true)
              selectedConversationRef.current = selectedConversation;
              const {data, }: AxiosResponse<ConversationPromptType[]> = await axiosInstance.get(`conversation_details?conversation_id=${selectedConversation}`)
              
              const tempConversations: ConversationPrompt[] = data.map((item) => new ConversationPrompt({
                  message: item.message,
                  user_created: item.user_created,
                  created_timestamp: item.created_timestamp
              }))
              conversationRef.current = tempConversations
              setConversationDetails(tempConversations)

          }finally{
              //setPromptProcessing(false)

          }
          
      }
  }

  useEffect(() => {
      GetConversationDetails();
  }, [selectedConversation])

    async function GetAnnouncements(){
        const response: AxiosResponse<AnnouncementType[]> = await axiosInstance.get('announcment/get/')
        setAnnouncement(response.data.map((status, message) => new Announcement({
            status: status,
            message: message
        })))

    }

    useEffect(() => {
        GetAnnouncements();
    }, [selectedConversation])

    useEffect(() => {
      /* register a consistent channel name for identifing this chat messages */
      const channelName = `ACCOUNT_ID_${account?.email}`

      /* subscribe to channel and register callback */        
      subscribe(channelName, (message: string) => {
          /* when a message is received just add it to the UI */
          
          if (message === 'END_OF_THE_STREAM_ENDER_GAME_42'){
              messageResponsePart.current = 0
          
              conversationRef.current.pop()
          
              //handleAssistantPrompt({prompt: messageRef.current})
              setConversationDetails([...conversationRef.current, new ConversationPrompt({message: `${messageRef.current}`, user_created:false})])
              messageRef.current = ''
              setStateMessage('')
          }
          else if (message === 'START_OF_THE_STREAM_ENDER_GAME_42'){
              messageResponsePart.current = 2

          }else if (message === 'CONVERSATION_ID'){
              messageResponsePart.current = 1
          }else{
              if (messageResponsePart.current === 1){
                  // this has to do with the conversation id
                  if(!selectedConversation){
                      //console.log("we have a new conversation")
                      setSelectedConversation(Number(message))
                  }   
              }
              else if (messageResponsePart.current === 2){
                  messageRef.current += message
                  setStateMessage(messageRef.current)

              }
          }
          
      })

      return () => {
          /* unsubscribe from channel during cleanup */
          unsubscribe(channelName)
      }
  }, [account, subscribe, unsubscribe])
    
    const conversationTitle = conversations.find(item => item.id === selectedConversation)?.title ?? 'New Conversation';
    const colorName = darkMode ? 'light' : 'dark';
    
    

    const handlePromptSubmit = async ({prompt, file, fileType}: PromptValues, {resetForm}: any): Promise<void> => {
        
      // send the prompt to be saved 
      console.log(fileType)
      try{
          const tempConversations: ConversationPrompt[] = [...conversationDetails, new ConversationPrompt({message: prompt, user_created:true}), new ConversationPrompt({message: '', user_created:false})]
          conversationRef.current = tempConversations
          setConversationDetails(tempConversations)
          // TODO: add the file here
          console.log(`Sending message. ${prompt} ${selectedConversation} ${fileType}`)
          sendMessage(prompt, selectedConversation, file, fileType)
          resetForm();
          
          
      }catch(e){
          console.log(`error ${e}`)
          // TODO: make this user friendly
      }
  }
    
return(
          <DashboardLayout>
              <Header2 />
              <MDBox sx={{mt:5}}>
                
              </MDBox>1
              <MDBox sx={{ margin: '0 auto', width: '80%', height: '80%', minHeight: '80%', maxHeight: '80%', align:'center'}}>
              <Card>
                <CardContent>
                  <MDTypography variant="h3" >
                    {conversationTitle}

                  </MDTypography>
                </CardContent>
                <Divider />
                <CardContent>
                {selectedConversation ?
                conversationDetails.map((convo_detail) => 
                    convo_detail.message.length >0 ? 
                    <ConversationDetailCard message={convo_detail.message} user_created={convo_detail.user_created} key={convo_detail.id}/> : <ConversationDetailCard message={stateMessage} user_created={convo_detail.user_created} key={convo_detail.id}/>
                )
                :
                <Markdown className='text-center' color='inherit'>Either select a previous conversation on start a new one.</Markdown>
                }
                </CardContent>
                <Divider />
                      <CardFooter>
                        <Formik
                        initialValues={{
                          prompt: '',
                          file: null,
                          fileType: null,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handlePromptSubmit}
                        >
                          {(formik)=>
                            <Form>

                              
                              <Field
                                name={"prompt"}
                                fullWidth
                                as={MDInput}
                                label={'Prompt'}
                                errorstring={<ErrorMessage name={'prompt'}/>}
                                size={'large'}
                                role={undefined}
                                tabIndex={-1}
                                variant={"outlined"}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <MDButton
                                        component="label"
                                        startIcon={<AttachFile/>}
                                        role={undefined}
                                        tabIndex={-1}
                                        color={buttonColor}
                                      >
                                        <VisuallyHiddenInput
                                            type="file"
                                            accept='.csv,.xlsx,.txt'
                                            
                                            onChange={(event) => {
                                                const file = event.target.files?.[0];
                                                //console.log(file)
                                                if (file) {
                                                    formik.setFieldValue('file',file)
                                                    formik.setFieldValue('fileType',file.type)
                                                } else {
                                                    formik.setFieldValue('file',null)
                                                    formik.setFieldValue('fileType',null)
                                                }
                                                
                                            }}
                                        /> 
                                      </MDButton>
                                      <MDButton
                                        type={'submit'}
                                        startIcon={<Send />}
                                        disabled={!formik.isValid}
                                        color={buttonColor}
                                      >
                                        <></>
                                      </MDButton>
                                    </InputAdornment>
                                  )
                                }}
                                
                              
                              >
                              </Field>
                            </Form>
                          }
                          </Formik>
                          

                        {/* <MDInput
                          label="Prompt"
                         /> */}
                      </CardFooter>
                  </Card>
                  <Footer />
              </MDBox>
              
          </DashboardLayout>
  )
}

const AsyncDashboard2 = ({}): JSX.Element => {
    return(
      

      
        <DashboardWrapperLayout>
          <AsyncDashboardInner />
        </DashboardWrapperLayout>
      
      
    )

    
}

export default AsyncDashboard2