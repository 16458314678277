import { Card, CardContent, Divider, Switch, TextField } from "@mui/material";
import { Feedback } from "../../data";
import { axiosInstance } from "../../../axiosApi";
import MDTypography from "../../ui-kit/components/MDTypography";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import CustomSelect, { CustomSelectItem } from "../../components/CustomSelect/CustomSelect";
import MDButton from "../../ui-kit/components/MDButton";
import { useMaterialUIController } from "../../ui-kit/context";

type FeedbackSubmitValues = {
    text: string
    title: string
    category: string
  }

  type FeedbackSubmitCardProps = {
    feedbacks: Feedback[],
    setFeedbacks: React.Dispatch<React.SetStateAction<Feedback[]>>
}

const validataionSchema = Yup.object().shape({
    text: Yup.string().min(1, "Need to have at least one character").required("This is requried"),
    title: Yup.string().min(1, "Need to have at least one character").required("This is requried"),
    category: Yup.string().required("Required")
}
)

const categories: CustomSelectItem[] = [
  {
    label: "Bug",
    value: "BUG"
  },
  {
    label: "Enhancement",
    value: "ENCHANCEMENT"
  },
  {
    label: "Other",
    value: "OTHER"
  }
];

const FeedbackSubmitCard = ({feedbacks, setFeedbacks}: FeedbackSubmitCardProps) => {
    const [controller, dispatch] = useMaterialUIController();
    const {
      darkMode,
    } = controller;
    const buttonColor = darkMode? 'dark' : 'light';
    console.log(buttonColor, darkMode)
    const handleFeedbackSubmit = async ({text, title, category}: FeedbackSubmitValues, {resetForm}: any): Promise<void> => {
      //console.log(text, title, category)
        try{
          await axiosInstance.post('/feedbacks/', {
            text: text,
            title: title,
            category: category
          })
          resetForm();
          // put message here
          setFeedbacks([...feedbacks, new Feedback({
    
            title: title,
            text: text,
            status: 'SUBMITTED',
            category: category,
            
          })])
        } catch{
          // put a message here
        }
      }
      return(
        <>
        <Card>
          <CardContent>
            <MDTypography variant="h3">
            Submit Feedback

            </MDTypography>
          </CardContent>
          <Divider />
        
          <CardContent>
            <Formik
            initialValues={{
              text: '',
              title: '',
              category: '',
            }}
            onSubmit={handleFeedbackSubmit}
            validationSchema={validataionSchema}
            validateOnMount>
              {(formik) => 
                <Form>
                  <div className="row">
                    <div className='col-6'>
                    <TextField
                      label='Title'
                      name='title'
                      onChange={formik.handleChange}
                      value={formik.values.title}
                      fullWidth={true}

                      variant={"outlined"} //enables special material-ui styling
                      
                      multiline={false}
                      
                    />
                    </div>
                    <div className='col-6'>
                      <CustomSelect
                        name="category"
                        items={categories}
                        label="Category"
                        required 
                        />
                    </div>
                    <div className="row">
                    <div className='col'>
                    <TextField
                      label='Text'
                      name='text'
                      onChange={formik.handleChange}
                      value={formik.values.text}
                      fullWidth={true}

                      variant={"outlined"} //enables special material-ui styling
                      size={"small"}
                      multiline={false}
                      margin={"dense"}
                      rows={3}
                      minRows={3}
                      maxRows={10}
                    />
                    </div>
    
                    </div>
                    <div className='row'>

                    
                    <div className='col'>
                    <Divider />
                      <MDButton
                        type={'submit'}
                        disabled={!formik.isValid}
                        color={buttonColor}
                        >
                            <MDTypography variant="h6"> Submit</MDTypography>
    
                      </MDButton>
                      </div>
                      
                    </div>
                  </div>
                  
                </Form>
              }
            </Formik>
            
            
          </CardContent>
        </Card>
        </>
      )
}

export default FeedbackSubmitCard;